
import NotesComponent from '~/modules/booking/components/appointment/forms/organisms/Notes';
import DatepickerComponent from '~/modules/booking/components/appointment/forms/organisms/datepicker/Calendar';
import { useAppointmentFormStore } from '~/modules/booking/stores/appointmentForm';
import BoldButton from "~/bold/components/molecules/BoldButton.vue";
import { storeToRefs } from 'pinia'
import dateHelper from "~/helpers/dateHelper";
import {updateAppointment} from "~/modules/booking/composables/appointment/update";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { SfInput } from '@storefront-ui/vue';

import {
  defineComponent,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';

export default defineComponent({
  components: {
    SfInput,
    BoldButton,
    NotesComponent,
    DatepickerComponent,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    appointmentId: {
      type: Number
    },
    serviceData: {
      type: Object
    },
    pharmacyData: {
      type: Object
    },
  },
  middleware: 'is-authenticated', // is only for logged in users
  setup(props) {
    const {
      result: updateAppointmentsResult,
      error: updateAppointmentsError,
      put: updateAppointmentsData,
      loadingUpdateAppointment
    } = updateAppointment();

    const router = useRouter();
    const {app} = useContext();

    const store = useAppointmentFormStore()
    const {
      notes,
      appointmentStart,
      appointmentEnd,
      telephone,
      fiscalCode,
      appointmentStartDate
    } = storeToRefs(store)

    const updateNotes = (newValue) => {
      notes.value = newValue;
    }

    const updateDateAndTimeslotSelected = (selectedDateAndTimeslot) => {
      // The selectedDateAndTimeSlot can be null when user has first selected a date and timeslot and then select
      // another date but not the timeslot. So when the user picks another date, the appointmentStart nad appointmentEnd
      // values must be reset.

      if (!selectedDateAndTimeslot) {
        appointmentStart.value = null;
        appointmentEnd.value = null;
        return
      }

      appointmentStart.value = dateHelper.dateObjectToString(new Date(selectedDateAndTimeslot.fromDateTime), false);

      const appointmentEndDateObject = dateHelper.addMinutesToDateObject(new Date(appointmentStart.value), selectedDateAndTimeslot.timelineInterval);
      appointmentEnd.value =  dateHelper.dateObjectToString(appointmentEndDateObject, false);
    }

    const updateAppointmentRequestObject = () => {
      return {
        "appointment" : {
          "id": props.appointmentId,
          "notes": notes.value,
          "appointmentStart": appointmentStart.value,
          "appointmentEnd": appointmentEnd.value,
          "telephone": telephone.value,
          "fiscal_code": fiscalCode.value
        }
      }
    }

    const submitAppointment = async () => {
      await updateAppointmentsData(
          updateAppointmentRequestObject()
      );

      // Error value is null and value of createAppointmentsResult === true, the appointment is created
      if (!updateAppointmentsError.value && updateAppointmentsResult.value) {
        let appointmentId = null;
        for (const response of updateAppointmentsResult.value) { appointmentId = response.appointment_id}
        await router.push(app.localeRoute({name: 'customer-view-appointment', params: {id: appointmentId}, query: { updated: 'true' }}));
        return
      }
      alert('Appointment update Failed!') // TODO: vervangen
    }

    return {
      updateDateAndTimeslotSelected,
      updateNotes,
      appointmentStart,
      appointmentEnd,
      submitAppointment,
      telephone,
      fiscalCode,
      notes,
      appointmentStartDate
    };
  },
})
