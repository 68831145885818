var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.serviceData)?_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('DatepickerComponent',{attrs:{"fetchTimeslotsOnPageLoad":true,"defaultSelectedDate":_vm.appointmentStartDate,"serviceId":_vm.serviceData.id,"pharmacyId":_vm.pharmacyData.id},on:{"date-timeslot-selected":_vm.updateDateAndTimeslotSelected}}),_vm._v(" "),_c('div',{staticClass:"telephone-fiscal-code-container mt-10"},[_c('ValidationProvider',{attrs:{"name":"telephoneInput","rules":"required|telephone","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"type":"text","name":"telephoneInput","label":_vm.$t('Telephone'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"required":""},model:{value:(_vm.telephone),callback:function ($$v) {_vm.telephone=$$v},expression:"telephone"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"codeFiscaleInput","rules":"codice_fiscale","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{attrs:{"type":"text","name":"codeFiscaleInput","label":_vm.$t('Fiscal Code'),"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.fiscalCode),callback:function ($$v) {_vm.fiscalCode=$$v},expression:"fiscalCode"}})]}}],null,true)})],1),_vm._v(" "),_c('NotesComponent',{attrs:{"defaultNotes":_vm.notes},on:{"input":_vm.updateNotes}}),_vm._v(" "),_c('div',{staticClass:"button-container"},[_c('BoldButton',{staticClass:"secondary bg-system-400 text-white border-system-400 w-full hover:text-system-400",attrs:{"disabled":!_vm.appointmentStart || !_vm.appointmentEnd || invalid,"text":_vm.$t('Update appointment')},on:{"click":_vm.submitAppointment}})],1)]}}],null,false,854637766)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }