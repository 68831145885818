import { computed, ssrRef, useContext } from '@nuxtjs/composition-api';

export const updateAppointment = () => {
    const context = useContext();
    const result = ssrRef(null, `indexAppointments-result`);
    const loading = ssrRef(false, `indexAppointments-loading`);
    const error = ssrRef(null, `indexAppointments-error`);

    const put = async (params) => {
        const customerToken = context.$vsf.$magento.config.state.getCustomerToken();
        if (customerToken) {
            params.customerToken = customerToken
        }

        try {
            loading.value = true;
            result.value = await context.$vsf.$magentoRestApi.api.updateAppointment(params);
            error.value = null;
        } catch (exception) {
            error.value = exception;
            console.log(`createAppointments error`, exception);
        } finally {
            loading.value = false;
        }
    };

    return {
        put,
        result: computed(() => result.value),
        loading: computed(() => loading.value),
        error: computed(() => error.value)
    }
};
