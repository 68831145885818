import { computed, ssrRef, useContext } from '@nuxtjs/composition-api';

export const getAppointments = () => {
    const context = useContext();
    const result = ssrRef(null, `getAppointments-result`);
    const loading = ssrRef(false, `getAppointments-loading`);
    const error = ssrRef(null, `getAppointments-error`);

    const get = async (params) => {
        if (params === undefined) {
            params = {}
        }

        params.customerToken = context.$vsf.$magento.config.state.getCustomerToken();

        try {
            loading.value = true;
            result.value = await context.$vsf.$magentoRestApi.api.getAppointments(params);
            error.value = null;
        } catch (exception) {
            error.value = exception;
            console.log(`getAppointments error`, exception);
        } finally {
            loading.value = false;
        }
    };

    return {
        get,
        result: computed(() => result.value),
        loading: computed(() => loading.value),
        error: computed(() => error.value)
    }
};
