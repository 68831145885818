//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {getAppointments} from '~/modules/booking/composables/appointment/get';
import BoldButton from "~/bold/components/molecules/BoldButton.vue";
import AppointEditFormComponent from '~/modules/booking/components/appointment/forms/Edit/MyAccount/Edit';
import AppointmentOverviewComponent from '~/modules/booking/components/appointment/buttons/Overview';
import Tabs from '~/bold/components/molecules/Tabs/Tabs.vue'
import { useAppointmentFormStore } from '~/modules/booking/stores/appointmentForm';
import { storeToRefs } from 'pinia'

import {
  defineComponent,
  useFetch,
  ref,
} from '@nuxtjs/composition-api';

export default defineComponent({
  components: {
    BoldButton,
    AppointEditFormComponent,
    AppointmentOverviewComponent,
    Tabs
  },
  props: {
    id: {
      type: Number | String
    },
    current_date: {
      type: Number | String
    },
  },
  middleware: 'is-authenticated', // is only for log in users
  setup(props, context) {
    const {
      result: getAppointmentsResult,
      error: getAppointmentsError,
      get: getAppointmentsData,
      loadingGetAppointments
    } = getAppointments();

    const serviceData = ref(null);
    const pharmacyData = ref(null);
    const appointmentDateData = ref(null)
    const appointmentId = ref(null)

    const store = useAppointmentFormStore()
    const {
      notes,
      appointmentStart,
      appointmentStartTime,
      appointmentEnd,
      appointmentEndDate,
      appointmentEndTime,
      telephone,
      fiscalCode,
      appointmentStartDate
    } = storeToRefs(store)

    const loadAppointments = async () => {
      await getAppointmentsData({appointmentId: props.id});

      if (getAppointmentsError.value) {
        alert('Getting appointments failed!') // TODO vervangen
        return;
      }

      for (const appointmentData of getAppointmentsResult.value) {
        appointmentId.value = appointmentData['id']
        serviceData.value = appointmentData['service'];
        pharmacyData.value = appointmentData['pharmacy']
        appointmentDateData.value = appointmentData['date_time_info']
        appointmentStartTime.value =  appointmentData['date_time_info']['time_start']
        appointmentStartDate.value =  appointmentData['date_time_info']['date']
        appointmentStart.value = appointmentData['date_time_info']['date_time_start']
        appointmentEnd.value = appointmentData['date_time_info']['date_time_end']
        appointmentEndDate.value = appointmentData['date_time_info']['date']
        appointmentEndTime.value = appointmentData['date_time_info']['time_end']
        notes.value = appointmentData['notes']
        telephone.value = appointmentData['telephone']
        fiscalCode.value = appointmentData['fiscal_code']
      }
    }

    useFetch(async () => {
      await loadAppointments()
    });

    return {
      serviceData,
      pharmacyData,
      appointmentDateData,
      loadAppointments,
      getAppointmentsResult,
      appointmentId
    };
  },
})
