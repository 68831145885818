
import BoldButton from "~/bold/components/molecules/BoldButton.vue";

import {
  defineComponent,
  useRouter,
  useContext
} from '@nuxtjs/composition-api';

export default defineComponent({
  components: {
    BoldButton,
  },
  setup(props, context) {
    const router = useRouter();
    const {app} = useContext();

    const gotoOverviewPage = async () => {
      await router.push(app.localeRoute({name: 'customer-my-appointments'}));
    }
    return {
      gotoOverviewPage,
    };
  },
})
